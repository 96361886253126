import React, { Component, Text } from 'react';
import Inscription_Header from '../components/headerSection/inscription_header';
import ScrollupSection from '../components/scrollupSection/scrollUp';
import FooterSection from '../components/footerSection/footer';
import emailjs from 'emailjs-com';
// import MailNotification from 'react-notifications-component'
// import {store} from "react-notifications-component"
// import "animate.css"
// import "react-notifications-component/dist/theme.css" 

// const handleOnClickDefault = () =>{
//     store.addNotification({
//         title:"Ceci est un test",
//         message:"Ah oui ca fonctionne",
//         type: "success",
//         container: ["top"],
//         insert: "top"
//     })
// }

class Inscription extends Component {
    state = {
        initData: {}
    }

sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_o6b75pr', 'template_9z99yn7', e.target, 'user_eBPs16p0iwvGQqXYkrl4B')
        .then((result) => {
            console.log(result.text);
            alert("Nous avons bien reçu votre demande!");
          }, (error) => {
            console.log(error.text);
            alert("Une erreur est survenue! Réessayez.");
          });
          e.target.reset()
      }


render() {
        return (
            <div className="moscow miami">
                <div className="all-area section">
                <Inscription_Header imageData={"../img/logo-white.png"} />
                <div className="shapes-container welcome-area">
                    <div className="shape bg-shape" />
                </div>
                <div className="section-heading text-center bg-inherit ">
                    <br/><br/><br/>
                    <h2 className="">Formulaire de demande d'inscription</h2>
                </div>
                <div className="contact-box text-center">
                <form id="inscription-form" onSubmit={this.sendEmail}>
                                <div className="row" >
                                    <div className="col-md-6 mx-auto">
                                        <div className="form-group border">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="from_name"
                                                placeholder="Votre nom" 
                                                required="required" 
                                            />
                                        </div>
                                        <div className="form-group border">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="shopEmail"
                                                placeholder="Adresse mail" 
                                                required="required" 
                                            />
                                        </div>
                                        <div className="form-group border">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="shopPhoneNo"
                                                placeholder="Numéro de téléphone" 
                                                required="required" 
                                            />
                                        </div>
                                        <div className="form-group border">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="shopName"
                                                placeholder="Nom du commerce" 
                                                required="required" 
                                            />
                                        </div>
                                        <div className="form-group border">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="shopCategory"
                                                placeholder="Type de commerce (friterie, pizzeria, etc.)" 
                                                required="required" 
                                            />
                                        </div>
                                        <div className="form-group border">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="shopAddress"
                                                placeholder="Adresse" 
                                                required="required" 
                                            />
                                        </div>
                                        <div className="form-group border">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="shopTVA"
                                                placeholder="Numéro de TVA" 
                                                required="required" 
                                            />
                                        </div>
                                    <div className="col-12">
                                        <button className="btn btn-bordered mt-3 mt-sm-4" type="submit">Envoyer</button>
                                        <div><br/></div>
                                    </div>
                                    </div>
                                    
                                </div>
                            </form>
                            </div>
                            <FooterSection />
                </div>          
            </div>
        );
}

}





export default Inscription;