import firebase from 'firebase'

var firebaseApp = firebase.initializeApp({
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    apiKey: "AIzaSyDMtlA50R1VRVuO_-6s84GccQUaV4kn7JM",
    authDomain: "rn-shop-app-8e2bb.firebaseapp.com",
    databaseURL: "https://rn-shop-app-8e2bb.firebaseio.com",
    projectId: "rn-shop-app-8e2bb",
    storageBucket: "rn-shop-app-8e2bb.appspot.com",
    messagingSenderId: "192359996792",
    appId: "1:192359996792:web:630b12e502b195add13c8d",
    measurementId: "G-YE46VGHVDC",
});

var db = firebaseApp.firestore();

export { db };
