import React, { Component } from 'react';

const initData = {
    heading: "Télécharger Yummy",
  headingText: "Disponible sur Android et iOS, smartphones et tablettes!",
  headingTextTwo: "Une application simple d'utilisation, gratuite et sans pub! Rien de tel pour commander en toute sécurité chez vos commerçants préférés.",
  downloadData: [
    {
      id: 1,
      linkClass: "btn btn-bordered",
      iconClass: "icofont icofont-brand-android-robot dsp-tc",
      iconText: "Maintenant sur",
      iconSpan: "Google Play",
      download_link: "https://play.google.com/store/apps/details?id=be.yummy_app.yummy"
    },
    {
      id: 2,
      linkClass: "btn btn-bordered",
      iconClass: "icofont icofont-brand-apple dsp-tc",
      iconText: "Maintenant sur",
      iconSpan: "Apple Store",
      download_link: "https://apps.apple.com/be/app/yummy-belgique/id1567189854?l=fr"
    },
  ]
}


class downloadOne extends Component {
    state = {
        initData: {},
        data: {},
        downloadData: []
    }

    componentDidMount(){
        this.setState({
            initData: initData,
            downloadData: initData.downloadData,
        })
    }
    render() {
        return (
            <section id="download" className="section download-area ptb_100">
                {/* Shapes Container */}
                <div className="shapes-container d-none d-sm-block">
                    <div className="shape-2" />
                    <div className="shape-3" />
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Download Text */}
                        <div className="download-text text-center">
                            <h2>{this.state.initData.heading}</h2>
                            <p className="my-3">{this.state.initData.headingText}</p>
                            <p className="d-none d-sm-block my-3">{this.state.initData.headingTextTwo}</p>
                            {/* Store Buttons */}
                            <div className="button-group store-buttons">
                                {this.state.downloadData.map((item, idx) => {
                                    return(
                                        <a key={`do_${idx}`} href={item.download_link} className={item.linkClass}>
                                            <i className={item.iconClass} />
                                            <p className="dsp-tc">{item.iconText}
                                                <br /> <span>{item.iconSpan}</span></p>
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default downloadOne;