import React, { Component } from 'react';
import axios from 'axios';

//const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json/themeOnePricingSection";



class pricingOne extends Component {
    state = {
        data: {},
        priceData: []
    }
    componentDidMount() {
        this.setState({
            data: {
                "heading": "Un abonnement à la carte!",
                "headingText": "Vous choisissez les options qui vous conviennent et adaptez votre abonnement à votre situation. Les prix affichés sont HTVA. ",
                "headingTextTwo": ""
            },
            priceData: [
                {
                    "id": 1,
                    "planClass": "single-price-plan",
                    "planTitle": "éclaireur",
                    "planPrice": "0€",
                    "planPackage": "/mois",
                    "li_1": true,
                    "plan_li_1": "Votre catalogue en ligne",
                    "li_2": true,
                    "plan_li_2": "50 photos",
                    "li_3": false,
                    "plan_li_3": "Commandes en ligne",
                    "li_4": false,
                    "plan_li_4": "0 commande/mois",
                    "li_5": false,
                    "plan_li_5": "Candenceur de commande",
                    "li_6": false,
                    "plan_li_6": "Impression des tickets",
                    "btnText": "Choisir"
                },
                // {
                //     "id": 2,
                //     "planClass": "single-price-plan",
                //     "planTitle": "Membre",
                //     "planPrice": "29€",
                //     "planPackage": "/mois",
                //     "li_1": true,
                //     "plan_li_1": "Votre catalogue en ligne",
                //     "li_2": true,
                //     "plan_li_2": "50 photos",
                //     "li_3": true,
                //     "plan_li_3": "Commandes en ligne",
                //     "li_4": true,
                //     "plan_li_4": "200 commandes/mois",
                //     "li_5": true,
                //     "plan_li_5": "Candenceur de commande",
                //     "li_6": false,
                //     "plan_li_6": "Impression des tickets",
                //     "btnText": "Choisir"
                // },
                // {
                //     "id": 3,
                //     "planClass": "single-price-plan featured",
                //     "planTitle": "Partenaire",
                //     "planPrice": "59€",
                //     "planPackage": "/mois",
                //     "li_1": true,
                //     "plan_li_1": "Votre catalogue en ligne",
                //     "li_2": true,
                //     "plan_li_2": "100 photos",
                //     "li_3": true,
                //     "plan_li_3": "Commandes en ligne",
                //     "li_4": true,
                //     "plan_li_4": "500 commandes/mois",
                //     "li_5": true,
                //     "plan_li_5": "Candenceur de commande",
                //     "li_6": true,
                //     "plan_li_6": "Impression des tickets",
                //     "btnText": "Choisir"
                // },
                // {
                //     "id": 4,
                //     "planClass": "single-price-plan",
                //     "planTitle": "Pionnier",
                //     "planPrice": "99€",
                //     "planPackage": "/mois",
                //     "li_1": true,
                //     "plan_li_1": "Votre catalogue en ligne",
                //     "li_2": true,
                //     "plan_li_2": "150 photos",
                //     "li_3": true,
                //     "plan_li_3": "Commandes en ligne",
                //     "li_4": true,
                //     "plan_li_4": "Commandes illimitées",
                //     "li_5": true,
                //     "plan_li_5": "Candenceur de commande",
                //     "li_6": true,
                //     "plan_li_6": "Impression des tickets",
                //     "btnText": "Choisir"
                // }
            ]
        })
    }
    render() {
        return (
            <section id="pricing" className="section price-plan-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.data.headingTextTwo}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div key={`pd_0`} className={"single-price-plan col-12 col-md-10 col-lg-8"}>
                            <div className="price-header d-flex align-items-center justify-content-between my-4 px-3">
                                <h5 className="plan-title text-uppercase">
                                    {"Abonnement"}
                                </h5>
                                <div className="plan-cost">
                                    <span className="plan-price">{"29€/mois"}</span>
                                    <span className="plan-type text-uppercase">{""}</span>
                                </div>
                            </div>
                            <ul className="plan-features pb-3">
                                <li><b>INCLUS:</b></li>
                                <li><i className={"icofont-check"} />{"Votre catalogue en ligne"}</li>
                                <li><i className={"icofont-check"} />{"Commandes en ligne"}</li>
                                <li><i className={"icofont-check"} />{"Paiements en ligne (Implique des frais de transaction de 0.25€ + 2.9% par paiement en ligne)"}</li>
                                <li><b>OPTIONS:</b></li>
                                <li>Impression des commandes sur imprimante thermique <b>+19€/mois</b></li>
                                <li>Limiter le nombre de commande par 1/4h <b>+9€/mois</b></li>
                                <li>Pointer vous même les commandes avec la "Pointeuse" <b>+9€/mois</b></li>
                                <li>Paiements par QR code avec Payconiq <b>+9€/mois</b></li>
                                <li>Paiements par carte avec Sumup <b>+9€/mois</b></li>
                                <li>Paiements par chèque repas Monizze <b>+9€/mois</b></li>
                            </ul>
                            
                            <div className="plan-select">
                                <a className="btn btn-bordered text-uppercase" href="/inscription">{"Commencer"}</a>
                            </div>
                        </div>
                        {/* <div className="price-plan-wrapper">
                            {this.state.priceData.map((item, idx) => {
                                return (
                                    <div key={`pd_${idx}`} className={item.planClass}>
                                        <div className="price-header d-flex align-items-center justify-content-between my-4 px-3">
                                            <h5 className="plan-title text-uppercase">
                                                {item.planTitle}
                                            </h5>
                                            <div className="plan-cost">
                                                <span className="plan-price">{item.planPrice}</span>
                                                <span className="plan-type text-uppercase">{item.planPackage}</span>
                                            </div>
                                        </div>
                                        <ul className="plan-features pb-3">
                                            <li><i className={item.li_1 ? "icofont-check" : "icofont-close"} />{item.plan_li_1}</li>
                                            <li><i className={item.li_2 ? "icofont-check" : "icofont-close"} />{item.plan_li_2}</li>
                                            <li><i className={item.li_3 ? "icofont-check" : "icofont-close"} />{item.plan_li_3}</li>
                                            <li><i className={item.li_4 ? "icofont-check" : "icofont-close"} />{item.plan_li_4}</li>
                                            <li><i className={item.li_5 ? "icofont-check" : "icofont-close"} />{item.plan_li_5}</li>
                                            <li><i className={item.li_6 ? "icofont-check" : "icofont-close"} />{item.plan_li_6}</li>
                                        </ul>
                                        <div className="plan-select">
                                            <a className="btn btn-bordered text-uppercase" href="/inscription">{item.btnText}</a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default pricingOne;