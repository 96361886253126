import React, { Component } from 'react';
import axios from 'axios';
import Lottie from 'react-lottie';
import deliveryData from "../../lottie/delivery.json";
import onsiteData from "../../lottie/onsite.json";
import takeawayData from "../../lottie/takeaway.json";

const deliveryOptions = {
    loop: true,
    autoplay: true,
    animationData: deliveryData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const onsiteOptions = {
    loop: true,
    autoplay: true,
    animationData: onsiteData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const takeawayOptions = {
    loop: true,
    autoplay: true,
    animationData: takeawayData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json/themeOnePromoSection";

class PromoMethods extends Component {
    state = {
        data: {},
        promoDataTwo: [
            {
                "id": 1,
                "promoImage": "/img/delivery-man.png",
                "promoAnimation": deliveryOptions,
                "promoAlt": "restaurant livraison",
                "promoImageTitle": "resto livraison",
                "promoTitle": "Commande à livrer",
                "promoText": "Définissez le montant minimum, votre rayon de livraison et plus encore! Recevez les commandes et les adresses de vos clients directement sur l'appli ou imprimez-les sur ticket."
            },
            {
                "id": 2,
                "promoImage": "/img/take-away.png",
                "promoAnimation": takeawayOptions,
                "promoAlt": "restaurant a emporter",
                "promoImageTitle": "vente à emporter restaurant",
                "promoTitle": "Commande à emporter",
                "promoText": "Choisissez le nombre maximum de commandes par 1/4h pour ne jamais être débordé! Notifiez vos clients lorsque leur commande est prête ou d'un éventuel retard."
            },
            {
                "id": 3,
                "promoImage": "/img/onsite.png",
                "promoAnimation": onsiteOptions,
                "promoAlt": "restaurant en ligne",
                "promoImageTitle": "restaurant commande en ligne",
                "promoTitle": "Commande sur place",
                "promoText": "Dégorgez vos files d'attentes et augmenter la satisfaction de vos clients en permettant à vos clients de commander et payer via leur smartphone."
            }
        ]
    }
    // componentDidMount(){
    //     axios.get(`${BASE_URL}`)
    //         .then(res => {
    //             this.setState({
    //                 data: res.data,
    //                 promoDataTwo: res.data.promoDataTwo
    //             })
    //             console.log(this.state.data)
    //         })
    //     .catch(err => console.log(err))
    // }
    render() {
        return (
            <section id="benifits" className="section benifits-area ptb_100">
                <div className="container">
                    <div className="row">
                        {/* Benifits Item */}
                        {this.state.promoDataTwo.map((item, idx) => {
                            return (
                                <div key={`pt_${idx}`} className="col-12 col-sm-6 col-md-4" data-aos="fade-up">
                                    <div className="benifits-item text-center p-3">
                                        <div className="feature-icon">
                                            {/* <img src={item.promoImage} alt={item.promoAlt} title={item.promoImageTitle} /> */}
                                            <Lottie
                                                options={item.promoAnimation}
                                                height={250}
                                                width={250}
                                            />
                                        </div>
                                        {/* Benifits Text */}
                                        <div className="benifits-text">
                                            <h3 className="mb-2">{item.promoTitle}</h3>
                                            <p>{item.promoText}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default PromoMethods;