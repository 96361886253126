import React, { Component } from 'react';

const initData = {
    heading: "Configurez votre shop en quelques étapes",
    headingText: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingTextTwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const imgData = [
    {
        image: "/img/step01.jpg",
        title: "service de livraison restaurant",
        alt: "site de livraison"
    },
    {
        image: "/img/step02.jpg",
        title: "application livraison repas",
        alt: "restaurant commande en ligne"
    },
    {
        image: "/img/step03.jpg",
        title: "Vendre ses repas en ligne ",
        alt: "Vendre ses repas en ligne"
    },
    {
        image: "/img/step04.jpg",
        title: "Commander en ligne",
        alt: "Commander en ligne"
    }
]

const workData = [
    {
        linkClass: "pager-item active",
        title: "Customisez votre shop",
        text: "Changez la photo de votre shop, définissez vos horaires, votre prosition et plus encore!"
    },
    {
        linkClass: "pager-item",
        title: "Ajoutez des catégories",
        text: "Choisissez une icône par catégorie et commencez à organiser vos produits!"
    },
    {
        linkClass: "pager-item",
        title: "Ajoutez des produits",
        text: "Prenez une photo de votre plat, ajoutez un nom, un prix, une description et le tour est joué!"
    },
    {
        linkClass: "pager-item",
        title: "Votre shop est prêt",
        text: "C'est parti! Vos clients peuvent commencer à passer commande!"
    }
]

class workOne extends Component {
    state = {
        initData: {},
        data: [],
        workData: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: imgData,
            workData: workData
        })
    }
    render() {
        return (
            <section className="section work-area ptb_100">
                {/* Work Slider Wrapper */}
                <div className="work-wrapper  d-md-block">
                    <div className="work-slider-wrapper" data-aos="zoom-in">
                        {/* Work Slider */}
                        <ul className="work-slider owl-carousel">
                            {this.state.data.map((item, idx) => {
                                return(
                                    <li key={`wd_${idx}`} className="slide-item">
                                        <img src={item.image} title={item.title} alt={item.alt} />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center d-md-none">
                        {/* Section Heading */}
                        <div className="col-12 col-md-10 col-lg-6">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                                {/* <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p> */}
                                {/* <p className="d-block d-sm-none mt-4">{this.state.initData.headingTextTwo}</p> */}
                            </div>
                        </div>
                    </div>
                    {/* Work Content */}
                    <div className="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
                        {this.state.workData.map((item, idx) => {
                            return(
                                <div key={`wdo_${idx}`} className="col-12 col-sm-6">
                                    <a href="#" className={item.linkClass}>
                                    {/* Single Work */}
                                    <div className="single-work d-inline-block text-center p-4">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default workOne;