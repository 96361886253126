import React, { Component } from 'react';
import axios from 'axios';

//const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json/themeOneAboutSection";

const initData = {
    headingSpan: "menu par Qr Code",
    heading: "Votre menu digitalisé",
    headingText: "Sur Yummy, chaque commerçant reçoit son propre QR code dès le premier lancement de l'application. Disposez ensuite votre QR code sur les tables de votre restaurant, sur la porte d'entrée de votre établissement ou même sur votre foodrtruck! Vos clients accèderont à votre menu en un instant et pourront commander et payer avec leur téléphone.",
    headingTextTwo: "Entrez dans l'air du temps avec votre menu par QR code!",
    aboutThumb: "/img/qrcode.png"
}


class aboutQr extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section id="qr-code" className="section about-app-area ptb_100">
                <div className="shapes-container">
                    <div className="shape-1" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6">
                            {/* About Text */}
                            <div className="about-text">
                                {/* Headings */}
                                <div className="headings d-flex align-items-center mb-4">
                                    <span className="text-uppercase d-none d-md-block d-sm-block">{this.state.initData.headingSpan}</span>   
                                </div>
                                <h1 className="text-capitalize my-3">{this.state.initData.headingSpan}</h1>
                                <h2 className="text-capitalize my-3">{this.state.initData.heading}</h2>
                                <p className="my-3">{this.state.initData.headingText}</p>
                                <p className="my-3">{this.state.initData.headingTextTwo}</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* About Thumb */}
                            <div className="about-thumb text-center">
                                <img src={this.state.initData.aboutThumb} title={"menu qr code"} alt="menu qr code" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default aboutQr;