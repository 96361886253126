import React, { Component, useState } from 'react';
import Inscription_Header from '../components/headerSection/inscription_header';
import FooterSection from '../components/footerSection/footer';

const TermsOfUse = () => {

        return (
            <div className="moscow miami">
                <div className="all-area section welcome-area bg-inherit">
                    <Inscription_Header imageData={"../img/logo-white.png"} />
                
                {/* <div className="shapes-container">
                    <div className="shape bg-shape" />
                </div> */}
                <div className="section-heading text-center bg-inherit">
                    <br/>
                    <h2 className="">Conditions d'utilisation</h2>
                </div>

                <div className="section-heading margin-horizontal text-left bg-inherit">
                    <br/>
                    <h3>Bienvenue chez Yummy ! </h3>
                    <br/>
                    <p>Les présentes Conditions d’utilisation régissent votre utilisation de Yummy et fournissent des informations concernant le Service Yummy, tel qu’il est présenté ci-dessous. Lorsque vous créez un compte Yummy ou que vous utilisez Yummy, vous acceptez les présentes Conditions. </p>
                    <br/>
                    <p>Nous avons écrit nos Conditions d’utilisation de façon à expliquer clairement le service que nous proposons et ce que nous demandons à toute personne utilisant Yummy.</p>
                    <br/>
                    <p>Veuillez accepter nos conditions générales d’utilisation pour pouvoir utiliser Yummy.</p>
                    <br/>
                    <h3>A. Le Service Yummy</h3>
                    <br/>
                    <p>Nous acceptons de vous fournir le Service Yummy. Le Service comprend l’ensemble des produits, des fonctionnalités, des applications, des services, des technologies et des logiciels Yummy que nous vous fournissons afin d’accomplir la mission de Yummy : Vous permettre de commander facilement, rapidement et en toute sécurité le menu de votre choix auprès de votre restaurateur préféré. Le Service inclut les éléments suivants (le « Service ») :</p>
                    <br/>
                    <p>Offrir des opportunités personnalisées pour accéder à vos produits favoris, découvrir, créer et envoyer votre commande. Nous sommes susceptibles de créer des systèmes qui tentent de déterminer quels produits et quels intérêts comptent pour vous, et nous utiliserions ces informations afin de vous aider à créer, à trouver et à consommer ce qu’il y a de meilleur pour vous. Cela consiste notamment à mettre en avant du contenu, des fonctionnalités et des offres susceptibles de vous intéresser, en fonction de ce que vous faites sur Yummy. </p>
                    <br/>
                    <h3>B. Créer un environnement positif, intuitif et sûr.</h3>
                    <br/>
                    <p>Nous développons et utilisons des outils, et offrons des ressources aux utilisateurs Yummy afin de contribuer à rendre votre expérience utilisateur la plus positive et agréable possible. Nous avons également une équipe et des systèmes qui fonctionnent pour lutter contre les abus et les violations de nos Conditions et Politiques, ainsi que contre les comportements trompeurs ou les conduites abusives. Nous utilisons toutes les informations à notre disposition, y compris les vôtres, pour tenter de maintenir la sécurité de notre plateforme. </p>
                    <br/>
                    <h3>C. Assurer une infrastructure stable pour notre Service.</h3>
                    <br/>
                    <p>Afin de fournir notre Service, nous devons stocker des données. Cette infrastructure est uniquement détenue par Google et exploitée par Yummy Technologies.</p>
                    <br/>
                    <p>Nous utilisons vos données et les partageons avec nos partenaires tiers (restaurateurs associés à la plateforme Yummy) afin de vous montrer des publicités, des offres et d’autres contenus sponsorisés qui, à notre avis, vous intéresseront. Nous faisons de notre mieux pour que ce contenu enrichisse  votre expérience sur Yummy.</p>
                    <br/>
                    <h3>D. Politique d’utilisation des données</h3>
                    <br/>
                    <p>La fourniture de notre service nécessite la collecte et l’utilisation de vos informations. La Politique d’utilisation des données explique comment nous recueillons, utilisons et partageons des informations à travers Yummy. Elle présente également les différentes manières dont vous pouvez contrôler vos informations.</p>
                    <br/>
                    <h5>1. Vos engagements</h5>
                    <br/>
                    <p>En échange de notre engagement à vous fournir le Service, nous vous demandons de prendre les engagements suivants envers nous.</p>
                    <br/>
                    <p>Nous souhaitons que notre Service soit aussi ouvert et inclusif que possible, mais nous voulons également qu’il soit sûr, sécurisé et conforme à la loi. Par conséquent, nous avons besoin que vous vous engagiez à respecter quelques conditions pour devenir utilisateur de notre solution technologique.</p>
                    <br/>
                    <h5>2. Qui peut utiliser Yummy ?</h5>
                    <br/>
                    <p>Pour utiliser Yummy en tant que client d’établissements, vous devez avoir au moins 16 ans. Attention – pour la consommation d’alcool – vous devez respecter les lois applicables du pays dans lequel vous consommez.</p>
                    <br/>
                    <p>Pour utiliser Yummy en tant qu’établissement partenaire, votre activité doit être conforme à la loi et vous devez exercer votre activité en vertu des lois qui sont applicables dans votre pays.</p>
                    <br/>
                    <p>Vous ne devez pas être limité(e) par une interdiction de bénéficier d’un élément de notre Service en vertu des lois applicables ou d’utiliser des Services de paiement si vous figurez sur une liste noire applicable.</p>
                    <br/>
                    <p>Votre compte ne doit pas avoir été supprimé auparavant par nous en raison d’une violation de la loi ou de nos politiques.</p>
                    <br/>
                    <p>La fourniture d’un Service ouvert et sûr nécessite que chacun y apporte sa contribution.</p>
                    <br/>
                    <h5>3. Utilisations interdites de Yummy :</h5>
                    <br/>
                    <p>Vous ne pouvez pas usurper l’identité d’autrui, ni fournir des informations erronées à votre sujet. Vous devez nous fournir des informations exactes et à jour (y compris les informations d’inscription). Vous ne pouvez pas usurper l’identité de quelqu’un que vous n’êtes pas et vous ne pouvez pas créer de compte pour une autre personne, sauf si vous avez son autorisation expresse.</p>
                    <br/>
                    <p>Vous ne pouvez rien faire qui soit illégal, trompeur ou frauduleux, ni agir dans un but illicite ou interdit.</p>
                    <br/>
                    <p>Vous ne pouvez pas violer (ni aider ou inciter autrui à violer) les présentes Conditions ou nos politiques et la Politique du service Yummy.</p>
                    <br/>
                    <p>Vous ne pouvez rien faire qui interfère avec le fonctionnement prévu du Service ou qui nuise à celui-ci.</p>
                    <br/>
                    <p>Vous ne pouvez pas tenter de créer de comptes, ni de recueillir des informations ou d’y accéder de manière illégale.</p>
                    <br/>
                    <p>Cela inclut la création de comptes ou la collecte d’informations de manière automatisée et frauduleuse sans notre autorisation expresse.</p>
                    <br/>
                    <p>Dans le cadre de notre accord, vous nous accordez également les autorisations dont nous avons besoin pour fournir le Service. </p>
                    <br/>
                    <h5>4. Les autorisations que vous nous accordez :</h5>
                    <br/>
                    <p>Autorisation pour utiliser les informations relatives à votre profil et les informations relatives à vos habitudes et à vos actions en rapport avec les évènements effectués dans l’application. Vous acceptez en retour les publicités, les offres et tout autre contenu sponsorisé qui apparaissent lors de votre navigation dans Yummy, sans vous verser aucune compensation.</p>
                    <br/>
                    <p>Vous acceptez que nous téléchargions et installions des mises à jour du Service sur votre appareil.</p>
                    <br/>
                    <h5>5. Droits supplémentaires que nous conservons :</h5>
                    <br/>
                    <p>Vous devez obtenir notre autorisation écrite ou une autorisation dans le cadre d’une licence open source pour modifier, créer des œuvres dérivées, décompiler ou tenter d’extraire notre code source de toute autre manière.</p>
                    <br/>
                    <h5>6. Suppression de contenu, et désactivation ou résiliation d’un compte:</h5>
                    <br/>
                    <p>Nous pouvons supprimer tout contenu ou toute information que vous partagez sur le Service si nous pensons qu’il ou elle enfreint les présentes Conditions d’utilisation ou nos politiques, ou si la loi nous y oblige. Nous pouvons immédiatement refuser de vous fournir ou arrêter de vous fournir tout ou une partie du Service (y compris résilier ou désactiver votre compte) si vous : enfreignez manifestement, gravement ou à plusieurs reprises les présentes Conditions d’utilisation ou nos politiques. Si nous prenons des mesures afin de supprimer votre contenu pour avoir enfreint nos règles ou si nous désactivons ou résilions votre compte, nous vous en informerons si cela est approprié. Si vous estimez que votre compte a été résilié par erreur ou si vous souhaitez désactiver ou supprimer votre compte de façon permanente, veuillez consulter nos pages d’aide pour prendre connaissance des démarches.</p>
                    <br/>
                    <br/>
                    <p>Ce paragraphe et la section ci-dessous, intitulée « Notre accord et ce qu’il se passe en cas de désaccord », restera en vigueur même après la résiliation ou la suppression de votre compte.</p>
                    <br/>
                    <h3>E. Notre accord et ce qu’il se passe en cas de désaccord : </h3>
                    <br/>
                    <p>Si vous utilisez d’autres fonctionnalités ou des services associés, vous aurez l’occasion d’accepter les conditions supplémentaires qui feront partie de notre accord. Par exemple, si vous utilisez des fonctionnalités de paiement, vous devrez accepter les conditions de paiement qui entreront en vigueur lorsque le paiement mobile sera sollicité à travers l’application. En cas de conflit entre ces conditions et le présent accord, les conditions prévaudront. </p>
                    <br/>
                    <p>Si une quelconque disposition du présent accord est inexécutable, le reste de l’accord demeurera en vigueur.</p>
                    <br/>
                    <p>Toute modification de notre accord ou toute renonciation à celui-ci doit vous être communiquée par nous-même.</p>
                    <br/>
                    <p>Nous nous réservons tous les droits qui ne vous sont explicitement pas accordés. </p>
                    <br/>
                    <h3>F. Qui est responsable si un événement se produit ?</h3>
                    <br/>
                    <p>Nous utiliserons la compétence et l’attention raisonnables en vous fournissant notre service et en maintenant un environnement sûr, sécurisé et exempt d’erreur. En revanche, nous ne pouvons pas garantir que notre Service fonctionnera toujours sans interruption, retard, ni imperfection. Si nous avons utilisé la compétence et l’attention raisonnables, nous déclinons toute responsabilité pour : les pertes qui ne sont pas causées par notre manquement aux présentes Conditions ou par nos agissements ; les pertes que ni nous ni vous ne pouvons raisonnablement prévoir au moment où les présentes Conditions ont été conclues ; tout contenu offensant, inapproprié, obscène, illégal et les évènements échappant à notre contrôle raisonnable.</p>
                    <br/>
                    <p>Si un quelconque litige – qui n’est pas lié à l’utilisation de Yummy – survient entre l’utilisateur et l’établissement partenaire, Yummy n’est pas responsable. À ce moment-là, l’utilisateur n’est plus considéré comme un utilisateur mais comme un client/consommateur de l’établissement. Par conséquent, le client s’arrange directement avec l’établissement.</p>
                    <br/>
                    <h3>G. Comment nous gérons les litiges :</h3>
                    <br/>
                    <p>Si vous êtes un consommateur et que vous résidez habituellement dans un État membre de l’Union européenne, les lois de cet État membre s’appliqueront à toute réclamation, à toute cause d’action ou à tout litige à notre encontre, qui découlent des présentes Conditions ou y sont relatifs (« réclamation »), et vous pouvez résoudre votre réclamation devant tout tribunal de cet État membre qui est compétent pour statuer sur la réclamation. </p>
                    <br/>
                    <h3>H. Mise à jour des présentes conditions :</h3>
                    <br/>
                    <p>Il se peut que nous devions modifier notre Service et nos politiques et que nous devions apporter des modifications aux présentes Conditions afin qu'elles représentent fidèlement notre Service et nos politiques. Par la suite, si vous poursuivez votre utilisation du Service, vous serez lié(e) par les Conditions telles que mises à jour. Si vous ne souhaitez pas accepter les présentes Conditions ou les Conditions telles que mises à jour, vous pouvez supprimer votre compte et ne plus utiliser Yummy. </p>
                    <br/>
                    <p>En utilisant le service Yummy, vous acceptez de vous conformer à la présente politique. Nous nous réservons le droit de modifier la présente politique à tout moment et sans préavis. Nous vous invitons donc à la consulter régulièrement. Votre utilisation continue de la Plate-forme Yummy vaut acceptation des modifications.</p>
                    <br/>
                    <h3>I. Conditions générales</h3>
                    <br/>
                    <p>Le service proposé par Yummy vise à mettre en relation les consommateurs avec les restaurateurs. Voici les conditions et les dispositions qui s’appliquent :</p>
                    <br/>
                    <p>Ne faites rien qui déroute, induise en erreur, escroque, trompe ou harcèle un établissement partenaire à Yummy. Cela inclut les fausses commandes et les commandes impayées lorsque l’établissement propose notre service de « commandes connectées »</p>
                    <br/>
                    <p>Indiquez clairement et correctement votre identité lors de l’inscription.</p>
                    <br/>
                    <p>N’exposez pas vos identifiants Yummy sur les réseaux sociaux ou autre endroit pouvant être accessible par quiconque. </p>
                    <br/>
                    <p>Respectez notre politique de confidentialité.</p>
                    <br/>
                    <p>Tenez le contenu de votre compte utilisateur à jour au niveau de vos informations personnelles.</p>
                    <br/>
                    <p>Assurez une utilisation optimale du service Yummy. Assurez-vous que vos commandes soient correctes et évitez toute perturbation et nuisance au bon déroulement de l’application. </p>
                    <br/>
                    <p>N’autorisez pas un proche à entreprendre simultanément plusieurs actions de commandes lors de votre processus de commande. </p>
                    <br/>
                    <p>Protégez les informations que vous recevez de notre part, via e-mail ou sms, contre tout accès, toute utilisation ou toute divulgations non-autorisées.</p>
                    <br/>
                    <p>Ne transférez aucune donnée que vous recevez de nous (notamment les données comme un code de récupération en cas de mot de passe oublié) à quiconque.</p>
                    <br/>
                    <p>Respectez toutes les lois ou tous les règlements en vigueur. Ne prenez aucune action qui enfreint une quelconque mesure que nous avons mis en place pour assurer un fonctionnement optimal et sécurisé de nos services.</p>
                    <br />
                    <p>Dernière mise à jour : le 19 Mars 2021.</p>
                    <br />
                </div>
                    <FooterSection />
                </div>          
            </div>
        );
}

export default TermsOfUse;