import React, { Component, useState } from 'react';
import Inscription_Header from './headerSection/inscription_header';
import FooterSection from './footerSection/footer';

const Politics = () => {

        return (
            <div className="moscow miami">
                <div className="all-area section welcome-area bg-inherit">
                    <Inscription_Header imageData={"../img/logo-white.png"} />
                
                {/* <div className="shapes-container">
                    <div className="shape bg-shape" />
                </div> */}
                <div className="section-heading text-center bg-inherit">
                    <br/>
                    <h2 className="">Politique de confidentialité</h2>
                </div>

                <div className="section-heading margin-horizontal text-left bg-inherit">
                    <br/>
                    <h3>Informations aux utilisateurs</h3>
                    <br/>
                    <p>Notre conformité au RGPD – Règlement Général sur la Protection des Données est absolue et nous souhaitons d’emblée vous en informer. Le RGPD est d’application depuis le 25 mai 2018. Sachez que nous respectons votre vie privée et n'utilisons pas vos données sans votre consentement. En effet, votre consentement est demandé avant tout traitement éventuel de vos données.</p>
                    <br/>
                    <p>Nous entendons par traitement: l’échange de vos données avec un tiers. Nous pouvons vous assurer que les données récoltées et leur traitement par Yummy sont entièrement conformes à la législation en vigueur. Plus particulièrement, le traitement de vos données personnelles est effectué de façon conforme au RGPD. </p>
                    <br/>
                    <p>Veuillez prendre quelques minutes pour consulter notre politique de confidentialité et prendre connaissance des paramètres de données qui vous concernent.</p>
                    <br/>
                    <p>Voici les principaux sujets qui sont abordés :</p>
                    <br/>
                    <p> - Vos données personnelles, la collecte et l’utilisation de vos données;</p>
                    <p> - La gestion de vos données, vos droits et votre sécurité avec l’application Yummy;</p>
                    <p> - Notre politique d’utilisation des données et notre Politique d’utilisation des cookies.</p>
                    <br/>
                    <h3>Vos données personnelles</h3>
                    <br/>
                    <p>Le respect de la vie privée est un droit fondamental et Yummy en a pleinement conscience. C’est pourquoi vous êtes en droit de refuser le partage de vos données personnelles à travers le service que nous vous proposons. </p>
                    <br/>
                    <p>Votre Nom, Prénom, numéro de téléphone, adresse mail, localisation (optionnel) sont les données que nous recueillons. Notre service est entièrement conçu pour protéger ces informations.</p>
                    <br/>
                    <p>Nous souhaitons vous offrir une expérience digitale de qualité sans compromettre la protection de votre vie privée. Dès lors, le bon traitement de vos données est une nécessité absolue chez Yummy. En outre, il est essentiel de garder à l’esprit que vos données personnelles peuvent considérablement nous aider à comprendre vos centres d’intérêts, habitudes et préférences dans le but de satisfaire au mieux vos besoins.</p>
                    <br/>
                    <h3>Ce que Yummy collecte</h3>
                    <br/>
                    <p>Yummy ne détient pas l’ensemble de vos données personnelles. Seules les données essentielles à une utilisation optimale de notre service et à l’amélioration de celui-ci seront recueillies.</p>
                    <br/>
                    <p>Dans le cadre de notre service, nous utilisons :</p>
                    <br/>
                    <p> - Des données de connexion relatives aux utilisateurs;</p>
                    <p> - Les données* d'identification personnelles et électroniques.</p>
                    <br/>
                    <p>*Données concernées : Nom, prénom, adresse e-mail, numéro de téléphone, adresses IP, localisation, moments de connexion, type de navigateur, système d'exploitation, type d’appareil, modèle de l’appareil, options régionales et linguistiques, identifiants uniques de matériel et numéro de série de l’appareil.</p>
                    <br/>
                    <h3>Particularités financières</h3>
                    <br/>
                    <p>Le système des paiements est géré en intégralité par notre partenaire PayConiq. Seul PayConiq accède à vos informations de paiement. Yummy ne peut ni accéder, collecter, stocker ou partager vos informations de paiement. Les données visées dans ce paragraphe sont: </p>
                    <br/>
                    <p>- Les numéros d’identification et de comptes bancaires;</p>
                    <p>- Les numéros de cartes de crédit ou de débit;</p>
                    <p>- Les montants dus et payés et modes de paiement.</p>
                    <br/>
                    <h3>Caractéristiques personnelles</h3>
                    <br/>
                    <p>Cette catégorie regroupe vos détails personnels. C’est à dire votre âge, sexe et date de naissance.</p>
                    <br/>
                    <h3>Habitudes de vie</h3>
                    <br/>
                    <p>Cette catégorie* regroupe les habitudes et styles de vie.</p>
                    <br/>
                    <p>*Données concernées : Consommations habituelles, détails des commandes, comportements lors de la navigation dans l’application, les habitudes d'achat telles que la fréquence d'achat, l'heure préférée de la journée et du jour de la semaine, le pourcentage d'articles achetés en promotion, la gamme de produits, etc. Nous collectons également les détails liés aux consommations, incluant la date et l'heure de la prestation, la distance écoulée entre le moment de la transaction, jusqu’à l’envoi et la réception du produit, ainsi que toutes autres informations complémentaires. </p>
                    <br/>
                    <p>Notez bien que nos serveurs enregistrent automatiquement les informations comme les « données de connexion », les « données sur les appareils » ou les « données d’identification ». Ces données sont simplement générées par votre utilisation de notre service et votre interaction au sein de l’application comme lorsque vous vous connectez. Nous demandons également d’activer les notifications pour la localisation et l’accès à votre appareil photo. Cependant, la collecte de ces données n’est pas spécialement destinée à être traitée dans un cadre commercial. Chaque traitement de données ne sera effectué qu’après consentement explicite de votre part. Rassurez-vous, la collecte et l’éventuel traitement de vos données ne comportent aucun risque pour les droits et libertés des personnes concernées – c’est-à-dire pour vous-même.</p>
                    <br/>
                    <p>Au moment de votre inscription, Yummy vous demande d’accepter la politique d’utilisation de vos données. En nous donnant vos informations personnelles, vous consentez et acceptez à ce que nous collections, utilisions et divulguons celles-ci, en application de cette présente politique de confidentialité. Le consentement est donné de manière électronique.</p>
                    <br/>
                    <p>L’utilisation de notre service nécessite au préalable que vous créiez un compte sur la plateforme Yummy. Lorsque vous créez un compte, nous vous demandons de nous fournir certaines informations expliquées précédemment. Si vous décidez de ne pas nous fournir les informations personnelles nécessaires de base, nous pourrions ne pas être en mesure de vous offrir l’accessibilité à notre application. Vous ne pourrez tout simplement pas avoir accès au service.</p>
                    <br/>
                    <p>Dans certains cas exceptionnels et rares, nous pouvons collecter, utiliser ou divulguer des informations personnelles sans votre accord direct ou explicite. Nous pouvons utiliser vos informations personnelles lorsqu'elles ont été publiées de façon évidente ou intentionnellement de votre part sur un site internet, un forum ou une quelconque plateforme en ligne étant publique.</p>
                    <br/>
                    <p>Chez Yummy vos droits sont respectés : L’accès immédiat à vos données personnelles est possible et la suppression définitive de toutes vos données personnelles l’est également. Vous avez le droit de révoquer votre consentement à la collecte, ainsi que l'utilisation et la divulgation de vos informations personnelles. Vous avez également le droit à la modification de vos données personnelles en changeant à tout moment le contenu des informations vous concernant dans la fonctionnalité « Mon compte » dans l’application mobile. </p>
                    <br/>
                    <p>Si vous fournissez des informations de localisation durant le processus d'enregistrement ou à tout autre moment via vos paramètres, nous conserverons ces informations et les associerons à votre compte. Dans certains cas, nous pouvons collecter et conserver des informations sur votre localisation, par exemple en convertissant votre adresse IP en une géolocalisation approximative. Si vous utilisez des services mobiles, nous pouvons collecter automatiquement des données de localisation directement depuis votre appareil mobile, si celui-ci nous y autorise. Lors de chaque visite dans un de nos établissements partenaires, vous serez amené à partager votre localisation avec nous.</p>
                    <br/>
                    <p>Le délai de conservation de vos données demeure aussi court que nécessaire.</p>
                    <br/>
                    <p>La durée de conservation de vos données est la même pour chaque catégorie de données. La durée de conservation n’est pas définie en jours, mois, années, mais elle se caractérise par votre activité au sein de Yummy. </p>
                    <br/>
                    <p>Autrement dit, la conservation est illimitée pour autant que l'utilisateur est actif. Après l’inactivité de votre part depuis 12 mois et 1 jour, nous nous offrons le droit de conserver vos données pendant 1 an après cette absence de 12 mois et 1 jour. En effet, si aucune action de votre part n’est détectée au sein de l’application pendant un minimum de 1 an, nous considérerons tout de même que vos données sont encore utiles pour une année supplémentaire. Notez bien que, si vous décidez de supprimer votre compte, nous supprimerons immédiatement toutes les données que nous détenons à votre sujet. Par la présente politique, vous consentez que vos données soient collectées et traitées sur base des délais mentionnés ci-avant.</p>
                    <br/>
                    <p>Rappelons que votre consentement est révocable à tout moment en vertu du nouveau règlement sur la protection des données.</p>
                    <br/>
                    <h3>L’utilisation qu’en fait Yummy</h3>
                    <br/>
                    <p>Chez Yummy, nous utilisons vos données personnelles pour améliorer votre expérience utilisateur. Néanmoins, nous n’oublions pas de réaliser cette optimisation tout en ne portant pas atteinte à vos données privées et en continuant à les respecter. </p>
                    <br/>
                    <p>Pour tenir nos engagements d’anonymisation partielle, nous utilisons une procédure moderne de la confidentialité différentielle. Cette méthode de traitement consiste à mélanger vos données à des milliers d’autres pour n’obtenir que des graphiques généraux. Ce processus évite d’afficher des informations permettant de remonter jusqu’à vous. Ces schémas ou graphiques nous permettent de prendre connaissance des produits les plus consommés dans une zone géographique donnée ou encore de savoir quelle est la consommation de chaque sexe en fonction son âge (n’incluant pas les informations personnellement identifiables partagées à des fins commerciales). En définitive, nous mettons la priorité sur la confidentialité.</p>
                    <br/>
                    <p>Votre confort et votre expérience au sein des établissements partenaires de Yummy sont une de nos priorités. Voilà pourquoi les données que vous partagez lorsque vous exécutez une commande ou lorsque vous consultez le menu d’un établissement partenaire sont automatiquement communiquées à l’établissement dans lequel cette action a été réalisée. </p>
                    <br/>
                    <p>Cette approche de collaboration indirecte entre les établissements et leur clientèle permet à chaque établissement de continuellement se remettre en question vis-à-vis de la qualité du service et des produits qu’il propose sur base d’éléments objectifs.</p>
                    <br/>
                    <p>Ainsi, nous récupérons vos données afin de vous proposer des publicités adaptées et personnalisées à votre profil. Nous prévoyons également de mettre en place un système de publicités entièrement conçu pour pouvoir vous montrer des publicités pertinentes et utiles sans devoir révéler votre identité aux futurs annonceurs (via la confidentialité différentielle).</p>
                    <br/>
                    <p>Nous sommes susceptibles de recevoir également des données venant de nos partenaires et annonceurs à propos de vos interactions avec eux, mais également à propos de vous.</p>
                    <br/>
                    <h3>La gestion de vos données, vos droits et votre sécurité sur la plateforme web Yummy</h3>
                    <br/>
                    <p>Votre compte créé sur notre application n’est accessible que par vous. Lors de votre inscription, vous êtes invité à introduire le mot de passe que vous désirez. Pour renforcer votre sécurité, il peut arriver que nous vous envoyions un e-mail ou un sms de confirmation en fonction des informations que vous nous aurez communiqué afin de vous assurer que vous êtes bien la bonne personne. Choisissez un mot de passe aussi complexe que possible.</p>
                    <br/>
                    <h3>Notre application, vos décisions. </h3>
                    <br/>
                    <p>Lorsque notre application est installée sur votre appareil et tente d’accéder à une de vos données personnelles, comme votre appareil photo ou votre localisation, votre appareil vous demande d’abord votre autorisation. En effet, la plupart des plateformes mobiles (iOS, Android, ...) protègent certains types de données auxquelles les applications ne peuvent pas accéder sans le consentement de l’utilisateur. Ces plateformes utilisent différentes méthodes pour obtenir votre consentement. La plateforme iOS vous alerte la première fois que l’application Yummy demande l’accès à certains types de données et vous permet alors d’accepter, ou non, cette demande. Les appareils Android vous indiquent les accès demandés directement avant la première utilisation de l’application, ce qui équivaut alors à votre consentement. </p>
                    <br/>
                    <p>Dans certains appareils, vous pouvez également modifier ces autorisations à tout moment comme sur les appareils de la marque Apple.</p>
                    <br/>
                    <h3>Vous avez des droits et nous les prenons en compte.</h3>
                    <br/>
                    <p>Il existe des modalités applicables à tous vos droits et nous sommes conformes à ces modalités. Nous nous engageons à fournir – à la personne qui fait valoir son droit – les informations sur les mesures prises à la suite de la demande formulée, dans les meilleurs délais et en tout état de cause dans un délai de 1 mois à compter de la réception de la demande. Si nécessaire, ce délai peut être prolongé de 2 mois, compte tenu de la complexité et du nombre de demandes. Lorsqu’il y a une prolongation du délai, nous vous informons de cette prolongation et des motifs du report dans un délai de 1 mois à compter de la réception de la demande. Lorsque vous présentez votre demande sous une forme électronique, les informations sont fournies par voie électronique lorsque cela est possible, à moins que vous ne demandiez qu'il en soit autrement. Si nous décidons de ne pas donner suite à l’une de vos demandes, nous vous en informerons sans tarder et au plus tard dans un délai de 1 mois à compter de la réception de la demande. Nous vous ferons part des motifs de notre inaction/ refus et de la possibilité d'introduire une plainte auprès d'une autorité de contrôle ou de formuler un recours juridictionnel. </p>
                    <br/>
                    <p>De notre côté, nous prévoyons, tant pour la collecte directe qu’indirecte, de vous fournir au préalable des informations au sujet d’une éventuelle autre finalité et de toute autre information pertinente. Ce cas se présenterait si nous avions l'intention d'effectuer un traitement ultérieur de vos données à caractère personnel pour une finalité autre que celle pour laquelle vos données à caractère personnel ont été obtenues.</p>
                    <br/>
                    <p>Vos droits vous appartiennent, rappelons-les.</p>
                    <br/>
                    <h5>A. La rectification</h5>
                    <br/>
                    <p>En tant que personne concernée vous avez le droit d'obtenir, dans les meilleurs délais, la rectification des données à caractère personnel vous concernant qui sont inexactes.</p>
                    <br/>
                    <p>2.   Le droit à l’effacement</p>
                    <br/>
                    <p>Si vous le demandez, nous supprimons les données vous concernant dans les meilleurs délais. Vous avez également le droit d’obtenir sans frais la suppression (ou l’interdiction d’utilisation) de toute donnée à caractère personnel vous concernant qui, compte tenu du but du traitement/ de la collecte est incomplète ou non pertinente ou dont l’enregistrement, la communication ou la conservation sont interdits ou encore qui a été conservée au-delà de ce qui est nécessaire.</p>
                    <br/>
                    <p>3.   La portabilité</p>
                    <br/>
                    <p>Le RGPD introduit le nouveau droit à la portabilité. Ce droit vous permet d'obtenir que les données personnelles soient transmises directement de notre organisation à une autre, lorsque cela est techniquement possible. Ce droit recouvre 2 aspects : </p>
                    <br/>
                    <p> - Vous avez, tout d’abord, le droit de recevoir en retour les données personnelles que vous avez fournies à l’entité qui traite vos données. Nous sommes l’entité en question;</p>
                    <p> - Ensuite, vous avez le droit de transmettre ces données à un autre entité ou fournisseur de service sans que nous y fassions obstacle.</p>
                    <br/>
                    <p>4.   L’opposition</p>
                    <br/>
                    <p>Vous avez le droit de vous opposer à tout moment, pour des raisons tenant à une situation particulière, à un traitement des données à caractère personnel vous concernant.</p>
                    <br/>
                    <p>Le marketing direct dispose également d’un régime plus restrictif. Dans ce cas là aussi, vous pourrez vous opposer à tout moment et sans frais au traitement de vos données à des fins de marketing, et cela comprend également le droit de s’opposer au profilage dans la mesure où il est lié au marketing.</p>
                    <br/>
                    <p>La logique étant, pour le consentement, que vous pouvez à tout moment, retirer le consentement que vous avez donné. Une fois que le consentement est retiré, vous disposez du droit à l’effacement.</p>
                    <br/>
                    <p>5.   Le profilage</p>
                    <br/>
                    <p>Vous avez le droit de ne pas être soumis à une décision – produisant des effets juridiques à votre égard ou vous affectant de manière significative –, prise sur le seul fondement d'un traitement automatisé de données destiné à évaluer certains aspects de votre personnalité, tel que votre comportement.</p>
                    <br/>
                    <p>6.   Les plaintes et recours</p>
                    <br/>
                    <p>Sans préjudice de tout autre recours administratif ou juridictionnel, en tant que personne concernée, vous avez le droit d'introduire une plainte auprès d'une autorité de contrôle si vous considérez que la collecte et l'éventuel traitement de vos données à caractère personnel constituent une violation du RGPD.</p>
                    <br/>
                    <p>Vous pourrez introduire cette plainte :</p>
                    <br/>
                    <p>- Dans l'État membre dans lequel se trouve votre résidence habituelle.</p>
                    <p>- Dans l'État membre de votre lieu de travail.</p>
                    <p>- Dans l'État membre où la violation aurait été commise.</p>
                    <br/>
                    <p>Vous avez également le droit de contester la décision de l’autorité de contrôle. Ce que l’on appelle plus communément : le droit à un recours juridictionnel effectif contre l'autorité de contrôle.</p>
                    <br/>
                    <p>Une plainte contre l’autorité de contrôle est donc possible dans les cas suivants :</p>
                    <br/>
                    <p>- L’autorité de contrôle n’a pas traité la plainte, en tout ou en partie;</p>
                    <p>- L’autorité de contrôle ne vous a pas informé du suivi de votre plainte ou de l’issue du traitement de celle-ci (dans les 3 mois);</p>
                    <p>- Contre toute décision contraignante de l’autorité de contrôle (donc pas à l’encontre des avis ou des recommandations);</p>
                    <p>- En Belgique, toute action contre une autorité de contrôle doit être intentée devant le Conseil d’État ou devant la Cour d’appel de Bruxelles.</p>
                    <br/>
                    <p>7.   Accès et copie</p>
                    <br/>
                    <p>Vous avez le droit d'obtenir la confirmation que des données à caractère personnel vous concernant sont ou ne sont pas traitées, mais avez également le droit d'obtenir une copie de vos données qui sont traitées. Si vos données ont été collectées, mais pas traitées, nous le mentionnerons dans la copie.</p>
                    <br/>
                    <p>8.   Limitation du traitement</p>
                    <br/>
                    <p>Vous disposez d’un droit vous permettant d’obtenir la limitation du traitement de vos données. La limitation du traitement permet en principe d’uniquement stocker les données.</p>
                    <br/>
                    <p>Tous les droits dont vous disposez sont applicables au gérant d’un établissement, ainsi qu’aux employés concernés et dont les données sont traitées.</p>
                    <br/>
                    <h3>Politique d’utilisation des données et Politique d’utilisation des cookies</h3>
                    <br/>
                    <p>Nous avons mis en place un système pensé pour que vous puissiez aisément contrôler vos paramètres de données, votre vie privée et votre sécurité dans un espace qui leur est entièrement dédié, et cela, à n’importe quel moment depuis votre compte utilisateur. Nous avons également mis en place notre Politique d’utilisation des données et notre Politique d’utilisation des cookies pour nous permettre d’organiser et d’ajuster chaque élément pertinent composant notre service, et pour expliquer de manière plus détaillée comment nous procédons pour personnaliser votre expérience au sein de notre solution. Ces éléments que nous avons mis en œuvre sont :</p>
                    <br/>
                    <p>Une personnalisation de votre expérience au sein de notre service en fonction de votre profil grâce à l’enregistrement de vos données personnelles afin de vous offrir un service de qualité.</p>
                    <br/>
                    <p>L’affichage de publicités pertinentes et une adaptation des contenus publicitaires en fonction de votre profil.</p>
                    <br/>
                    <p>Un partage de vos données de façon non anonyme et anonyme à nos partenaires commerciaux dans l’objectif d’améliorer leur service et les produits proposés à leurs clients.</p>
                    <br/>
                    <p>La collecte et l’analyse de données par la procédure de la confidentialité différenciée pour l’optimisation de notre service, le partage d’informations à des fins commerciales et non commerciales permettant une anonymisation partielle de vos données personnelles et par conséquent le respect de votre vie privée.</p>
                    <br/>
                    <p>Tout utilisateur se doit de respecter l’image de Yummy en utilisant à bon escient et aux effets escomptés les services offerts par la société. Ainsi, toute dégradation ou nuisance apportée à des éléments matériels et/ou informatiques pourrait faire l’objet d’une poursuite judiciaire demandant le dédommagement des fautes occasionnées envers la société Yummy et/ou un collaborateur.</p>
                    <br/>
                    <h3>Politique d’utilisation des cookies</h3>
                    <br/>
                    <p>Yummy utilise les « cookies » qui sont des fichiers témoins ainsi que d’autres technologies d’identification dans son application, site internet, courriels et publicités aux fins mentionnées dans la présente politique.</p>
                    <br/>
                    <p>Qu’est-ce qu’un « cookie » ? Les « cookies » sont des petits fichiers texte qui sont stockés sur votre appareil, dans votre navigateur lors de la consultation des sites web, ainsi que les médias en ligne appartenant à Yummy.</p>
                    <br/>
                    <p>Yummy utilise cette technologie ainsi que d’autres dans les buts suivants :</p>
                    <br/>
                    <p>- L’authentification de l’utilisateur.</p>
                    <p>- L’enregistrement des préférences de l’utilisateur. </p>
                    <p>- L’enregistrement des paramètres de l’utilisateur. </p>
                    <p>- Générer des statistiques de popularité de notre contenu. </p>
                    <br/>
                    <p>Administration et évaluation de performance de nos campagnes publicitaires, de celles de nos partenaires et de nos annonceurs. </p>
                    <br/>
                    <p>Compréhension des comportements de navigation et des centres d’intérêt des utilisateurs de notre service, plus précisément l’analyse du trafic et des tendances. </p>
                    <br/>
                    <p>Nous sommes également susceptibles de nous fournir des mesures d’audience, des services d’analyses, de publier du contenu publicitaire internet pour notre compte et de suivre et communiquer les performances de ces publicités par le biais de tiers. Ces entités peuvent utiliser des balises web, cookies et d’autres technologies similaires pour identifier votre appareil lorsque vous visitez notre site web ainsi que d’autres ou utilisez notre service.</p>
                    <br/>
                    <p>Pour en savoir davantage sur l’utilisation et les options relatives aux cookies et à nos technologies décrites ci-dessus, veuillez consulter notre Déclaration applicable aux cookies.</p>
                    <br/>
                    <p>En utilisant nos services, vous vous engagez et reconnaissez avoir pris connaissance des documents ci-présents. Si vous ne souhaitez pas accepter l’ensemble de ces conditions/ politiques, nous ne pourrons donner suite à votre demande d’utilisation de notre service.</p>
                    <br/>
                </div>
                    <FooterSection />
                </div>          
            </div>
        );
}

export default Politics;