import React, { Component, Text, useState, useEffect } from 'react';

const initData = {
    heading: "Ton webshop en quelques clics",
    headingTwo: "àpd de seulement 29€/mois",
    content: "Sur place, à emporter ou à livrer, Yummy vous fait gagner du temps et de l'argent. Créez votre webshop très facilement et en toute autonomie depuis l'application mobile. ",
    btnTextRetail: "Crée ton shop maintenant ! 30 jours gratuit",
    btnTextEndUser: "Télécharge l'application",
    btnTextQr: "Avoir son QR code",
    btnTextTwo: "S'inscrire",
    welcomeThumb: "/img/welcome_mockup_2.png",
    facebookAd: "/video/facebook-ad.webm"
}
const { innerWidth: width, innerHeight: height } = window;

class welcomeEight extends Component {
    state = {
        initData: {}
    }
    componentDidMount() {
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-inherit h-100vh">
                <div className="shapes-container">
                    <div className="shape bg-shape" />
                </div>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-7 col-lg-6">
                            <div className="welcome-intro text-right">
                                <h1>{this.state.initData.heading}</h1>
                                <h2>{this.state.initData.headingTwo}</h2>
                                <p className="my-4">{this.state.initData.content}</p>
                                <div className="container">
                                    <div className="row">
                                        <a href="/inscription" className="row  btn btn-bordered d-md-inline d-sm-inline-block">{this.state.initData.btnTextRetail}</a>
                                    </div>
                                    <div><br /></div>
                                    <div className="row">
                                        <a href="#download" className="row btn btn-bordered d-md-inline d-sm-inline-block">{this.state.initData.btnTextEndUser}</a>
                                    </div><br />


                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-6">
                            {/* Welcome Thumb */}
                            <div className="text-center" data-aos="fade-right" data-aos-delay={500} data-aos-duration={1000}>
                                {/* <img className="d-sm-none d-md-block" title="application restaurant" style={{height: "100%", width: "100%"}} src={this.state.initData.welcomeThumb} alt="application restaurant" /> */}
                                <div className="about-thumb text-center">
                                    {width > 599 && <iframe width="500" height="500"
                                        src={this.state.initData.facebookAd}
                                        title="Yummy Belgique"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>}
                                    {width <= 599 && <iframe width="250" height="250"
                                        src={this.state.initData.facebookAd}
                                        title="Yummy Belgique"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default welcomeEight;