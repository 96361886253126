import React, { Component } from 'react';
import emailjs from 'emailjs-com';
// npm install emailjs-com --save
const initData = {
    heading: "Nous contacter",
    headingText: "Vous souhaitez des explications, une démonstration ou simplement nous poser une question? Complétez ce formulaire, nous vous recontacterons dès que possible.",
}

class contactSection extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }

    sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_o6b75pr', 'template_4cj25du', e.target, 'user_eBPs16p0iwvGQqXYkrl4B')
          .then((result) => {
              console.log(result.text);
              alert("Message envoyé!");
          }, (error) => {
              console.log(error.text);
              alert("Une erreur est survenue! Réessayez.");
          });
          e.target.reset()
      }

    render() {
        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-6">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {/* Contact Box */}
                            <div className="contact-box text-center">
                            {/* Contact Form */}
                            <form id="contact-form" onSubmit={this.sendEmail}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group border">
                                        <input type="text" className="form-control" name="name" placeholder="Votre nom" required="required" />
                                        </div>
                                        <div className="form-group">
                                        <input type="email" className="form-control" name="email" placeholder="Adresse mail" required="required" />
                                        </div>
                                        <div className="form-group">
                                        <input type="text" className="form-control" name="subject" placeholder="Sujet" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                        <textarea className="form-control" name="message" placeholder="Posez votre question" required="required" defaultValue={""} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-bordered mt-3 mt-sm-4" type="submit">Envoyer</button>
                                    </div>
                                </div>
                            </form>
                            {/* <p className="form-message" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}



export default contactSection;