import React, { Component } from 'react';
import axios from 'axios';

//const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json/themeOneAboutSection";

const initData = {
    headingSpan: "Intégration Payconiq",
    heading: "Paiements mobiles",
    headingText: "Le nombre de paiements par mobile est en pleine expansion. Avec presque 3 millions d'utilisateurs, Payconiq est devenu un leader sur le marché du paiement mobile. En tant qu'intégrateur officiel, Yummy ne passe par aucune autre plateforme de paiement, ce qui vous offre les prix les plus avantageux!",
    headingTextTwo: "Vous aussi surfez sur la vague et accepter les paiements Payconiq dans vote restaurant ou établissement!",
    aboutThumb: "/img/payconiq_logo2.png",
    aboutData: [
    {
      id: 1,
      linkClass: "btn btn-bordered",
      linkClassTwo: "btn btn-bordered text-white",
      iconClass: "icofont icofont-brand-android-robot dsp-tc",
      iconText: "GET IT ON",
      iconSpan: "Google Play"
    },
    {
    id: 2,
    linkClass: "btn btn-bordered",
    linkClassTwo: "btn btn-bordered text-white",
    iconClass: "icofont icofont-brand-apple dsp-tc",
    iconText: "AVAILABLE ON",
    iconSpan: "Apple Store"
    }
  ]
}


class aboutOne extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="section about-app-area ptb_100">
                <div className="shapes-container">
                    <div className="shape-1" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6">
                            {/* About Text */}
                            <div className="about-text">
                                {/* Headings */}
                                <div className="headings d-flex align-items-center mb-4">
                                    <span className="text-uppercase d-none d-md-block d-sm-block">{this.state.initData.headingSpan}</span>   
                                </div>
                                <h1 className="text-capitalize my-3">{this.state.initData.headingSpan}</h1>
                                <h2 className="text-capitalize my-3">{this.state.initData.heading}</h2>
                                <p className="my-3">{this.state.initData.headingText}</p>
                                <p className="my-3">{this.state.initData.headingTextTwo}</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* About Thumb */}
                            <div className="about-thumb text-center">
                                <img src={this.state.initData.aboutThumb} title={"payconiq"} alt="bancontact app" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default aboutOne;