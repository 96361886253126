import React, { Component } from 'react';
import axios from 'axios';

//const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json/themeOnePromoSection";

class promoOne extends Component {
    state = {
        data: {},
        promoData: []
    }
    componentDidMount() {
        this.setState({
            data: {},
            promoData: [
                {
                    "id": 1,
                    "promoImage": "/img/map.png",
                    "promoTitle": "Modifiez votre position",
                    "promoAlt": "site de livraison",
                    "promoText": "Vous tenez un foodtruck? Changez votre position au jour le jour pour que vos clients sachent toujours où vous êtes!"
                },
                {
                    "id": 2,
                    "promoImage": "/img/dashboard.png",
                    "promoTitle": "Résumé du jour",
                    "promoAlt": "nourriture livraison",
                    "promoText": "Visionnez le total des produits commandés par jour. Vous pourrez dès lors anticiper ou adapter votre production."
                },
                {
                    "id": 3,
                    "promoImage": "/img/bill.png",
                    "promoTitle": "Imprimez sur ticket",
                    "promoAlt": "commande en ligne",
                    "promoText": "Les commandes peuvent être imprimées sur imprimante thermique en réseau ou par Bluetooth, pratique si vous en avez déjà une."
                },
                {
                    "id": 4,
                    "promoImage": "/img/slow.png",
                    "promoTitle": "Définissez la cadence",
                    "promoAlt": "restaurant commande en ligne",
                    "promoText": "Vous pouvez définir le nombre maximum de commandes autorisées par 1/4h et ainsi adaptez votre production. Ne soyez jamais débordés!"
                }
            ]
        })
        // console.log(this.state.data)
    }
    render() {
        return (
            <section id="benifits" className="section benifits-area ptb_100">
                <div className="container">
                    <div className="row">
                        {/* Benifits Item */}
                        {this.state.promoData.map((item, idx) => {
                            return (
                                <div key={`po_${idx}`} className="col-12 col-sm-6 col-md-4 col-lg-3" data-aos="fade-up">
                                    <div className="benifits-item text-center p-3">
                                        <div className="feature-icon">
                                            <img src={item.promoImage} alt={item.promoAlt} title={item.promoTitle} />
                                        </div>
                                        {/* Benifits Text */}
                                        <div className="benifits-text">
                                            <h3 className="mb-2">{item.promoTitle}</h3>
                                            <p>{item.promoText}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default promoOne;