import React, { Component } from 'react';
import PromoMethods from '../promoSection/promoMethods';

const data = [
    {
        image: "/img/snack-shop.jpg",
        title: "livraison snack",
        alt: "snack",
        iconClass: "icofont-image"
    },
    {
        image: "/img/sushi-shop.jpg",
        title: "restaurant japonais",
        alt: "commander sushi",
        iconClass: "icofont-image"
    },
    {
        image: "/img/pizza-shop.jpg",
        title: "pizzeria livraison",
        alt: "pizza livraison",
        iconClass: "icofont-image"
    },
    {
        image: "/img/baker-shop.jpg",
        title: "commander à manger",
        alt: "application boulangerie",
        iconClass: "icofont-image"
    },
    {
        image: "/img/bistro-shop.jpg",
        title: "application bar",
        alt: "application prise de commande bar",
        iconClass: "icofont-image"
    },
    {
        image: "/img/cupcake-shop.jpg",
        title: "commander cupcake",
        alt: "commander des cupcakes",
        iconClass: "icofont-image"
    }
]

class screenshot extends Component {
    state = {
        data: []
    }
    componentDidMount() {
        this.setState({
            data: data
        })
    }
    render() {
        return (
            <section id="screenshots" className="section screenshots-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <h1 className="mb-2">Une appli tout-terrain</h1>
                        <PromoMethods />
                        <div className="col-12">
                            {/* App Screenshot Slider Area */}
                            <div className="app-screenshots">
                                {/* Single Screenshot Item */}
                                {this.state.data.map((item, idx) => {
                                    return (
                                        <div key={`sd_${idx}`} className="single-screenshot">
                                            <img src={item.image} alt={item.alt} title={item.title} />
                                            {/* Screenshots Overlay */}
                                            <div className="screenshots-overlay">
                                                <a href={item.image} data-fancybox="images"><i className={item.iconClass} /></a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default screenshot;