import React, { Component } from 'react';
import axios from 'axios';
import { init } from 'emailjs-com';

// const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json-2/themeOneFooterSection";
const initData = {
    
        image: "/img/logo-white.png",
        text: "Une équipe motivée jeune et dynamique prête à mettre tout en oeuvre pour vous satisfaire",
        linkText_1: "Liens utils",
        linkText_2: "Aide ",
        linkText_3: "Téléchargement ",
        iconList: [
          {
            id: 1,
            link: "facebook",
            iconClass: "fab fa-facebook-f",
            download_link: "https://www.facebook.com/Yummy-103541518462116/"
          },
          // {
          //   // id: 2,
          //   // link: "twitter",
          //   // iconClass: "fab fa-twitter"
          // },
        //   {
        //     id: 3,
        //     link: "google-plus",
        //     iconClass: "fab fa-google-plus-g"
        //   },
        //   {
        //     id: 4,
        //     link: "vine",
        //     iconClass: "fab fa-vine"
        //   }
        ],
        footerList_1: [
          {
            id: 1,
            text: "Home"
          },
          {
            id: 2,
            text: "About Us"
          },
          {
            id: 3,
            text: "Services"
          },
          {
            id: 4,
            text: "Blog"
          },
          {
            id: 5,
            text: "Contact"
          }
        ],
        footerList_2: [
          // {
          //   id: 1,
          //   text: "FAQ"
          // },
          {
            id: 2,
            text: "Politique de confidentialité",
            link: "/politics"
          },
          {
            id: 4,
            text: "Termes et conditions",
            link: "/termsofuse"
          },
          {
            id: 5,
            text: "Contact",
            link: "/#contact"
          }
        ],
        footerList_3: [
        //   {
        //     id: 1,
        //     text: "For Windows"
        //   },
        //   {
        //     id: 2,
        //     text: "For Linux"
        //   },
          {
            id: 3,
            text: "Pour IOS",
            download_link: "https://www.apple.com/befr/app-store/"
          },
          {
            id: 4,
            text: "Pour Android",
            download_link: "https://play.google.com/store?hl=fr&gl=US"
          },
        //   {
        //     id: 5,
        //     text: "For Mac"
        //   }
        ]
      
}


class footer extends Component {
    state = {
        initData: {},
        iconList: [],
        footerList_1: [],
        footerList_2: [],
        footerList_3: []
    }

    componentDidMount(){
        this.setState({
            initData: initData,
            iconList: initData.iconList,
            footerList_1: initData.footerList_1,
            footerList_2: initData.footerList_2,
            footerList_3: initData.footerList_3
        })
    }

    render() {
        return (
            <footer className="section footer-area footer-bg">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4">
                        {/* Footer Items */}
                        <div className="footer-items">
                            {/* Logo */}
                            <a className="navbar-brand" href="#">
                            <img className="logo" src={this.state.initData.image} alt="Yummy horeca" title="Yummy" />
                            </a>
                            <p className="text-white-50 mt-2 mb-3">{this.state.initData.text}</p>
                            {/* Social Icons */}
                            <div className="social-icons d-flex">
                                {this.state.iconList.map((item, idx) => {
                                    return(
                                        <a key={`sil_${idx}`} className={item.link} href={item.download_link}>
                                            <i className={item.iconClass} />
                                            <i className={item.iconClass} />
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-lg-3"> */}
                        {/* Footer Items */}
                        {/* <div className="footer-items"> */}
                            {/* Footer Title */}
                            {/* <h3 className="footer-title text-white mb-2">{this.state.initData.linkText_1}</h3>
                            <ul>
                                {this.state.footerList_1.map((item, idx) => {
                                    return(
                                        <li key={`flo_${idx}`} className="py-2"><a className="text-white-50" href="#">{item.text}</a></li>
                                    );
                                })}
                            </ul>
                        </div> */}
                        {/* </div> */}
                        <div className="col-12 col-sm-6 col-lg-4">
                        {/* Footer Items */}
                        <div className="footer-items">
                            {/* Footer Title */}
                            <h3 className="footer-title text-white mb-2">{this.state.initData.linkText_2}</h3>
                            <ul>
                                {this.state.footerList_2.map((item, idx) => {
                                    return(
                                        <li key={`flo_${idx}`} className="py-2"><a className="text-white-50" href={item.link}>{item.text}</a></li>
                                    );
                                })}
                            </ul>
                        </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                        {/* Footer Items */}
                        <div className="footer-items">
                            {/* Footer Title */}
                            <h3 className="footer-title text-white mb-2">{this.state.initData.linkText_3}</h3>
                            <ul>
                                {this.state.footerList_3.map((item, idx) => {
                                    return(
                                        <li key={`flo_${idx}`} className="py-2"><a className="text-white-50" href={item.download_link}>{item.text}</a></li>
                                    );
                                })}
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                    <div className="row">
                        <div className="col-12">
                        {/* Copyright Area */}
                        <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            {/* Copyright Left */}
                            <div className="copyright-left text-white-50">Copyrights 2021 Yummy Technologies All rights reserved.</div>
                            {/* Copyright Right */}
                            <div className="copyright-right text-white-50">Made with <i className="icofont-heart-alt" /> By <a className="text-white-50" href="https://yummy-technologies.be">Yummy Technologies</a></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default footer;