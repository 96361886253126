import React, { Component } from 'react';

const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json/themeOnePromoSection";

class promoTwo extends Component {
    state = {
        data: {},
        promoDataTwo: [
            {
                "id": 1,
                "promoImage": "/img/save-time.png",
                "promoImageAlt": "Gagner du temps",
                "promoTitle": "Gagnez du temps!",
                "promoText": "Fini les heures passées au téléphone en plein rush et vos serveurs ne doivent plus courir pour prendre les commandes!"
            },
            {
                "id": 2,
                "promoImage": "/img/money.png",
                "promoImageAlt": "abordable",
                "promoTitle": "Une solution abordable",
                "promoText": "Une application à un prix plus abordable et facile à prendre en main."
            },
            {
                "id": 3,
                "promoImage": "/img/kcal.png",
                "promoImageAlt": "Catalogue en ligne",
                "promoTitle": "Créez votre propre catalogue",
                "promoText": "Un nom, une photo, un prix et c'est en vente! Vous avez tous les outils en mains pour mettre à jour votre webshop."
            }
        ]
    }

    render() {
        return (
            <section id="benifits" className="section benifits-area ptb_100">
                <div className="container">
                <div className="row">
                    {/* Benifits Item */}
                    {this.state.promoDataTwo.map((item, idx) => {
                        return (
                            <div key={`pt_${idx}`} className="col-12 col-sm-6 col-md-4" data-aos="fade-up">
                                <div className="benifits-item text-center p-3">
                                    <div className="feature-icon">
                                        <img src={item.promoImage} title={item.promoImageAlt} alt={item.promoImageAlt} />
                                    </div>
                                    {/* Benifits Text */}
                                    <div className="benifits-text">
                                        <h3 className="mb-2">{item.promoTitle}</h3>
                                        <p>{item.promoText}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                </div>
            </section >
        );
    }
}

export default promoTwo;