import React, { Component } from 'react';
import axios from 'axios';

//const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json/themeOneAboutSection";

const initData = {
    headingSpan: "Commander devient un jeu d'enfants",
    heading: "Commander devient un jeu d'enfants",
    headingText: "Permettez à vos clients de commander avec une application simple et conviviale. ",
    aboutThumb: "/img/payconiq_logo2.png",
}


class aboutVideo extends Component {
    state = {
        initData: {}
    }
    componentDidMount() {
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="section about-app-area ptb_100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6">
                            {/* About Text */}
                            <div className="about-text">
                                {/* Headings */}
                                <div className="headings d-flex align-items-center mb-4">
                                    <span className="text-uppercase d-none d-md-block d-sm-block">{this.state.initData.headingSpan}</span>
                                </div>
                                {/* <h1 className="text-capitalize my-3">{this.state.initData.headingSpan}</h1> */}
                                <h2 className="text-capitalize my-3">{this.state.initData.heading}</h2>
                                {/* <p className="my-3">{this.state.initData.headingText}</p> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* About Thumb */}
                            <div className="about-thumb text-center">
                                <iframe width="100%" height="315" 
                                src="https://www.youtube.com/embed/jCDMzGCW5xw" title="Yummy Belgique" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default aboutVideo;